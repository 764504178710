<template>
    <div>
        <h2 class="ma-10">Store was reset</h2>
    </div>
</template>

<script>
import { mapMutations } from "vuex"

export default {
    methods: {
        ...mapMutations({
            resetStore: "documents/RESET_STORE",
        }),
    },
    mounted() {
        this.resetStore()
    }

}
</script>
